.profile-image {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

@media (prefers-color-scheme: dark) {
  pre {
    color: #d3d3d3;
  }
}

body {
  overflow-y: scroll;
}

/*# sourceMappingURL=index.109af734.css.map */
