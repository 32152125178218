.profile-image {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

@media (prefers-color-scheme: dark) {
  pre {
    color: #d3d3d3;
  }
}

body {
  // always show scrollbar, to prevent page from jumping around when users navigate between pages that have/dont have scrollbar
  overflow-y: scroll;
}
